<template>
    <div class="dashboard">
        <h1>類似キーワード一覧</h1>
        <div class="l-mb4">
            <router-link class="button--oval--primary" :to="{ name: 'similar_keywords_create' }" type="button" tag="button">新しい類似キーワードを登録する</router-link>
        </div>
        <table>
            <tr>
                <th v-for="data in tableSet" :key="data.key">
                    {{ data.head }}
                </th>
                <th class="is-right">&nbsp;</th>
            </tr>
            <tr v-for="(list, index) in sortingListKeywords" :key="`list${index}`">
                <template v-for="data in tableSet">
                    <td v-if="data.key === 'similar_keywords'" :key="`list${index}${data.key}`" :class="data.class">
                        {{ list[data.key] | unionSimilarKeywords }}
                    </td>
                    <td v-else :key="`list${index}${data.key}`" :class="data.class">
                        {{ list[data.key] }}
                    </td>
                </template>
                <td class="is-right">
                    <router-link class="button--primary" :to="{ name: 'similar_keywords_update', params: { id: String(list.id) }}" type="button" tag="button">編集</router-link>
                </td>
            </tr>
        </table>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";
    import sorts from "@/mixins/sort";

    const tableKeywords = [
        {
            "class": "",
            "head": "キーワード名",
            "key": "keyword_name",
        },
        {
            "class": "is-wrap",
            "head": "類似キーワード",
            "key": "similar_keywords",
        },
    ];

    export default {
        "filters": {
            unionSimilarKeywords(array) {
                return array.map((item) => item).join(",");
            },
        },
        "mixins": [
            common,
            sorts,
        ],
        data() {
            return {
                "listKeywords": [],
                "tableSet": tableKeywords,
            };
        },
        "computed": {
            sortingListKeywords() {
                // キーワード名で昇順
                const list = this.listKeywords;
                const key = "keyword_name";
                return this.$_sorting(list, key);
            },
        },
        created() {
            this.fetchingListKeywords();
        },
        "methods": {
            fetchingListKeywords() {
                // 全類似キーワードデータ取得
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/similar_keywords`,
                    this.$_requestConfig()
                ).then(response => {
                    this.listKeywords = response.data;
                }).catch(() => {
                    this.$_modalError("keywordFetchError");
                });
            },
        },
    };
</script>
