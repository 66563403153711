"use strict";

export default {
    "methods": {
        $_sorting(list, key, type = "asc") {
            const compare = (a, b) => {
                if (a[key] < b[key]) {
                    if (type === "desc") {
                        return 1;
                    } else {
                        return -1;
                    }
                } else if (a[key] > b[key]) {
                    if (type === "desc") {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                return 0;
            };
            return list.sort(compare);
        },
    },
};
